export const en =
  { projects: "projects"
  , svgIcons: "svg icons"
  , curriculum: "curriculum"
  // Home page
  , welcome: "Welcome"
  // Projects
  , date: "date"
  , category: "category"
  , technologies: "technologies"
  , repository: "repository"
  , linkToRepository: "link to repository"
  , project: "project"
  , linkToProject: "link to project"
  , synopsis: "synopsis"
  , svgIcons_name: "Haskell svg icons"
  , svgIcons_category: "Haskell library, open source"
  , svgIcons_synopsis1: "Images and icons in SVG format done with the programming language Haskell, uploaded to Hackage, the official Haskell package archive."
  , svgIcons_synopsis2: "You can learn how to use the library by reading the haddock documentation in the Hackage site, or check this same website to see the mosaics."
  , cloister_name: "Cloister"
  , cloister_category: "Online forum"
  , cloister_synopsis1: "Online forum which I developed as part of a hiring process, took about 14 days to reach the current state."
  , cloister_synopsis2: "The initial requirements of the challenge were much simpler and did not ask me to develop an entire forum. However, I wanted to use the opportunity to learn more about Nextjs, so I chose to build something more complex than the simple app they were asking for. I also worked on my Typescript skills, which was part of the initial requirements."
  , oracle: "oracle"
  , of: "of"
  , yiChing: "Yi Ching"
  , ioracle_name: "Yi Ching Oracle"
  , ioracle_category: "Book of Changes"
  , ioracle_synopsis: "Website about the Yi Ching, the Book of Mutations (or Changes). A very brief history of the book is exposed (the book is three millenia old), and services for consulting the book are offered at a very modest price."
  // , ehv_name: "Egipto Historia Viva"
  // , ehv_category: "e-shop"
  // , ehv_synopsis: "Online shop selling replicas of Egyptian antiquities."
  // SvgIcons
  , svgDoneWith: "SVG done with"
  , svgIcons_t1: "After coding some SVG icons and images for multiple projects using the "
  , svgIcons_t2: " Haskell package, I have collected all that work into my own Haskell package. "
  , svgIcons_t3: "You can find all icons and images, together with the official documentation, here: "
  , svgIcons_t4: "Some of those images are designed to work as mosaic tiles, and it is hard to appreciate how the full mosaic works by only looking at the basic tile. "
  , svgIcons_t5: "Consequently, I have made this page for illustration and didactic purposes."
  , svgIcons_t6: "Below this introduction, you will find the Haskell code that generates most of the SVGs used in this website (my portfolio). "
  , svgIcons_t7: "Afterwards, you will find a sample of every mosaic from the package "
  // Curriculum
  , cvDesc1: "Mathematician, programmer and web developer (UX/UI & frontend)."
  , cvDesc2: "Self-taught, creative, perfectionist; I achieve simple and elegant solutions. I love clean, concise and reusable code."
  , cvDesc3: "Check my online portfolio to see what I can do, you will find the source code in my github."
  , cvLocation: "Madrid, Spain"
  , studies: "studies and certifications"
  , bootcamp: "Bootcamp Fullstack Web Programmer"
  , utretchUni: "Utretch University, Utretch, The Netherlands"
  , mathsDegree: "Degree in Mathematics"
  , basicEducation: "Basic and high education"
  , stMichaels: "St.Michaels School, Boadilla del Monte"
  , workExperience: "work experience"
  , workExp2_title: "Backend Haskell developer"
  , workExp2_desc: "Web backend programmer with Haskell for a fantasy football startup in the incubation stage"
  , workExp1_title: "Private lessons teacher"
  , workExp1_location: "Majadahonda Academy"
  , workExp1_desc: "Private teacher for boys and girls in high school, mainly for the subject of Mathematics"
  , skills: "Skills"
  , languages: "Languages"
  , social: "Social"
  , topology: "Topology"
  , spanish: "Spanish"
  , english: "English"
  , french: "French"
  , native: "Native"
  , professional: "Professional"
  , beginner: "Beginner"
  }