import React from 'react';

export const haskell = 
<svg viewBox="-1 -1 2 2" height="100px" width="100px" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g className="HaskellSvgIcons__haskell" fill="none" stroke="black" strokeWidth="0.04">
        <path fill="#453a62" d="M -0.9520000000000001,-0.672 L -0.6160000000000001,-0.672 L -0.16800000000000004,0.0 L -0.6160000000000001,0.672 L -0.9520000000000001,0.672 L -0.504,0.0 Z" id="HaskellSvgIcons-haskellLogo-left" />
        <path fill="#5e5086" d="M -0.504,-0.672 L -0.16800000000000004,-0.672 L 0.7279999999999999,0.672 L 0.392,0.672 L 0.11199999999999999,0.252 L -0.16800000000000004,0.672 L -0.504,0.672 L -5.600000000000005e-2,0.0 Z" id="HaskellSvgIcons-haskellLogo-mid" />
        <path fill="#8f4e8b" d="M 0.3539199999999999,-5.600000000000005e-2 L 0.20495999999999992,-0.28 L 0.9520000000000001,-0.28 L 0.9520000000000001,-5.600000000000005e-2 ZM 0.5779199999999999,0.28 L 0.4289599999999999,5.599999999999994e-2 L 0.9520000000000001,5.599999999999994e-2 L 0.9520000000000001,0.28 Z" id="HaskellSvgIcons-haskellLogo-right" />
    </g>
</svg>
